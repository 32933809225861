import { signOut as authSignOut } from 'next-auth/react';

export const signOut = async () => {
  try {
    await authSignOut({
      redirect: true,
      callbackUrl: '/login'
    });
  } catch (error) {
    // Fallback if the signOut fails
    window.location.href = '/login';
  }
};
