import useLocalStorage from 'hooks/useLocalStorage';
import { Public_Sans, Cairo } from 'next/font/google';
import { createContext } from 'react';
import defaultConfig from '~/config';
import { LanguageCode, useGetSupportedLanguages } from '~/hooks/useSupportedLanguages';

const publicSans = Public_Sans({
  subsets: ['latin'],
  weight: ['400', '500', '300', '700']
});

const cairo = Cairo({
  subsets: ['arabic'],
  weight: ['400', '700']
});

type ConfigType = typeof defaultConfig;

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext<
  ConfigType & {
    onChangeContainer?: () => void;
    onChangeLocalization?: (lang: string) => void;
    onChangeMode?: (mode: string) => void;
    onChangePresetColor?: (theme: string) => void;
    onChangeDirection?: (direction: string) => void;
    onChangeMiniDrawer?: (miniDrawer: boolean) => void;
    onChangeMenuOrientation?: (layout: string) => void;
  }
>(defaultConfig);

type ConfigProviderProps = React.PropsWithChildren<unknown>;

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage<ConfigType>('mantis-react-next-ts-config', defaultConfig);
  const supportedLanguages = useGetSupportedLanguages();

  const onChangeContainer = () => {
    setConfig({
      ...config,
      container: !config.container
    });
  };

  const onChangeLocalization = (lang: string) => {
    const selectedLanguage = supportedLanguages.find((language) => language.code === lang);
    if (selectedLanguage) {
      setConfig({
        ...config,
        i18n: lang as LanguageCode,
        themeDirection: selectedLanguage.isRTL ? 'rtl' : 'ltr'
      });
    }
  };

  const onChangeMode = (mode: string) => {
    setConfig({
      ...config,
      mode
    });
  };

  const onChangePresetColor = (theme: string) => {
    setConfig({
      ...config,
      presetColor: theme
    });
  };

  const onChangeDirection = (direction: string) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  const onChangeMiniDrawer = (miniDrawer: boolean) => {
    setConfig({
      ...config,
      miniDrawer
    });
  };

  const onChangeMenuOrientation = (layout: string) => {
    setConfig({
      ...config,
      menuOrientation: layout
    });
  };

  const fontFamily = config.i18n === 'ar' ? cairo.style.fontFamily : publicSans.style.fontFamily;

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        fontFamily,
        onChangeContainer,
        onChangeLocalization,
        onChangeMode,
        onChangePresetColor,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeMenuOrientation
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
