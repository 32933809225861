import createCache from '@emotion/cache';
// material-ui
import { CacheProvider } from '@emotion/react';
import { Box } from '@mui/material';
// project import
import useConfig from 'hooks/useConfig';
import * as PropTypes from 'prop-types';
import { useEffect } from 'react';
// third-party
import rtlPlugin from 'stylis-plugin-rtl';
// project import
import { ThemeDirection } from '~/config';

// ==============================|| RTL LAYOUT ||============================== //

const RTLLayout = ({ children, forcedDirection }) => {
  const { themeDirection: configuredDirection } = useConfig();
  const themeDirection = forcedDirection ?? configuredDirection;

  useEffect(() => {
    if (forcedDirection) {
      return;
    }

    document.dir = themeDirection;
  }, [themeDirection, forcedDirection]);

  const cacheRtl = createCache({
    key: themeDirection === ThemeDirection.RTL ? 'rtl' : 'css',
    prepend: true,
    stylisPlugins: themeDirection === ThemeDirection.RTL ? [rtlPlugin] : []
  });

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

RTLLayout.propTypes = {
  children: PropTypes.node,
  forcedDirection: PropTypes.string
};

export default RTLLayout;
